import React, { useEffect, useState } from 'react';
import { db, auth } from '../../firebase';
import { collection, getDocs, addDoc, deleteDoc, doc, query, where } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Booking.css';

const Booking = () => {
  const [spaces, setSpaces] = useState([]);
  const [user, setUser] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userHasBooking, setUserHasBooking] = useState(false);

  useEffect(() => {
    const fetchSpaces = async () => {
      const querySnapshot = await getDocs(collection(db, "parkingSpaces"));
      const sortedSpaces = querySnapshot.docs
        .map(doc => ({ ...doc.data(), id: doc.id }))
        .sort((a, b) => a.name.localeCompare(b.name)); // Sorter på navn
      setSpaces(sortedSpaces);
    };
    fetchSpaces();

    onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
  }, []);

  useEffect(() => {
    const fetchReservationsForDate = async () => {
      setLoading(true);
      const dateString = selectedDate.toISOString().split('T')[0];
      const q = query(
        collection(db, "reservations"),
        where("date", "==", dateString)
      );

      const querySnapshot = await getDocs(q);
      const dateReservations = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setReservations(dateReservations);

      // Sjekk om brukeren allerede har en reservasjon på denne datoen
      const userReservation = dateReservations.some(
        (reservation) => reservation.userId === user?.uid
      );
      setUserHasBooking(userReservation);

      setLoading(false);
    };

    fetchReservationsForDate();
  }, [selectedDate, user]);

  const handleBooking = async (spaceId) => {
    if (!user) {
      toast.error("Vennligst logg inn for å reservere en plass.");
      return;
    }

    if (userHasBooking) {
      toast.error("Du har allerede en reservasjon for denne datoen.");
      return;
    }

    const dateString = selectedDate.toISOString().split('T')[0];

    try {
      await addDoc(collection(db, "reservations"), {
        spaceId,
        userId: user.uid,
        email: user.email,
        date: dateString
      });
      toast.success("Reservasjon vellykket!");
      setReservations([...reservations, { spaceId, userId: user.uid, date: dateString }]);
      setUserHasBooking(true); // Oppdater tilstanden for å hindre flere reservasjoner
    } catch (error) {
      console.error("Error creating reservation:", error);
    }
  };

  const handleDeleteBooking = async (reservationId) => {
    try {
      await deleteDoc(doc(db, "reservations", reservationId));
      toast.success("Reservasjon slettet.");
      setReservations(reservations.filter(reservation => reservation.id !== reservationId));
      setUserHasBooking(false); // Tillat ny reservasjon etter sletting
    } catch (error) {
      console.error("Error deleting reservation:", error);
      toast.error("Kunne ikke slette reservasjonen.");
    }
  };

  return (
    <div className="container booking-container">
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} closeOnClick pauseOnHover draggable />

      <h2>Parkeringsplasser</h2>
      
      <div className="date-picker">
        <label>Velg en dato:</label>
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="dd.MM.yyyy"
          className="input"
        />
      </div>

      <div className="space-list">
        {loading ? (
          <p>Laster parkeringsplasser...</p>
        ) : (
          spaces.map(space => {
            const reservation = reservations.find(res => res.spaceId === space.id);
            const isReservedByUser = reservation && reservation.userId === user?.uid;

            return (
              <div key={space.id} className="space-item">
                <div className="space-info">
                  <h3>{space.name}</h3>
                  {reservation && (
                    <p className="reserved-by">Reservert av: {reservation.email}</p>
                  )}
                </div>
                {reservation ? (
                  isReservedByUser ? (
                    <button onClick={() => handleDeleteBooking(reservation.id)}>Avbestill</button>
                  ) : (
                    <button disabled>Reservert</button>
                  )
                ) : (
                  <button onClick={() => handleBooking(space.id)} disabled={userHasBooking}>
                    Reserver
                  </button>
                )}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default Booking;